<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Rol"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="item.ROL"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Grupo AD"
            label-for="adGroup"
          >
            <b-form-input
              id="code"
              v-model="item.GRUPO"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-input
              id="code"
              v-model="item.DESCRIPCION"
              placeholder=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Estado"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="item.STATUS"
              label="status"
              :options="statuses"
              :reduce="option => option.idStatus"
            />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            to="roles-list"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  name: 'CentersTypesAddVue',
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      item: {},
      loading: false,
    }
  },
  computed: {
    statuses() {
      return this.$store.getters['catalog/allStatuses']
    },
  },
  created() {
    this.load()
  },
  methods: {
    async load() {
      try {
        this.loading = true
        const { data } = await this.$http.get(`${environment.uri}/users/role/${this.$route.params.id}`)
        this.item = data
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.apiMessage || 'No fue posible procesar su solicitud',
            icon: 'BellIcon',
          },
        })
      } finally {
        this.loading = false
      }
    },
    async save(item) {
      try {
        this.loading = true
        await this.$http.put(`${environment.uri}/users/role/${item.ID_REGISTRO}`, {
          rol: item.ROL,
          grupo: item.GRUPO,
          descripcion: item.DESCRIPCION,
          accesos: item.ACCESOS,
          status: item.STATUS,
        })

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Actualizado',
            icon: 'CoffeeIcon',
            variant: 'success',
          },
        })

        await this.$router.replace('/portal/admin/roles-list')
      } catch (error) {
        console.error(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.apiMessage || 'No fue posible procesar su solicitud',
            icon: 'BellIcon',
          },
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
